<script lang="ts" setup>
const { login } = useStrapiAuth();

const email = ref("");
const password = ref("");
const disPlayInvalidInput = ref(false);
const errorMessage = ref("");
const isLoading = ref(false)

async function onLogin() {
  isLoading.value = true;
  disPlayInvalidInput.value = false;

  setTimeout(async () => {
    if ((email?.value && password?.value)) {
      try {
        await login({ identifier: email.value, password: password.value });
        navigateTo("/administration/dashboard");
      } catch {
        disPlayInvalidInput.value = true;
        errorMessage.value = "Den e-mail eller adgangskode, du indtastede, stemmer ikke overens med en bruger. Prøv venligst igen eller nulstil adgangskoden.";
      }
    } else {
      disPlayInvalidInput.value = true;
      errorMessage.value = "Den e-mail eller adgangskode, du indtastede, stemmer ikke overens med en bruger. Prøv venligst igen eller nulstil adgangskoden.";
    }
    isLoading.value = false;

  }, 250)
}
</script>

<template>
  <NuxtLayout>
    <div class="max-w-md mx-auto py-10 px-4 flex flex-col gap-3">
      <div class="text-2xl font-bold">
        Velkommen tilbage
      </div>

      <input type="email"
        v-model="email" placeholder="Email her..." @keyup.enter="onLogin"
        class="bg-gray-20 border border-secondary-50 text-gray-90 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-full p-2.5 dark:bg-gray-70 dark:border-gray-60 dark:placeholder-gray-40 dark:text-white dark:focus:ring-blue-50 dark:focus:border-blue-50" 
        required>
      <input type="password"
        v-model="password" placeholder="Kodeord her..." @keyup.enter="onLogin"
        class="bg-gray-20 border border-secondary-50 text-gray-90 text-sm rounded-lg focus:ring-blue-50 focus:border-blue-50 block w-full p-2.5 dark:bg-gray-70 dark:border-gray-60 dark:placeholder-gray-40 dark:text-white dark:focus:ring-blue-50 dark:focus:border-blue-50"
        required>

        <ButtonNormal
          buttonValue="Log ind"
          colorType="success"
          @click="onLogin"
          :disabled="isLoading"
        />
          
        <div v-if="disPlayInvalidInput" class="mb-8 text-sm">
          {{ errorMessage }}
        </div>
    </div>
  </NuxtLayout>
</template>